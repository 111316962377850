import axios from "axios"
var url_domain = window.location.host

// Default URL
//var config_url = "https://apilif.myticktap.com/api/1.0"

var config_url = "https://api.ligainternacional.net/api/1.0"
//var config_url = "http://127.0.0.1:3333/api/1.0"

if (url_domain === "ligainternacional.ticktaps.com"){
  config_url = "https://api.ligainternacional.net/api/1.0"
}
else if (url_domain === "app.ligainternacional.net"){
  config_url = "https://api.ligainternacional.net/api/1.0"
 }
else if (url_domain === "dashboard.ligainternacional.net"){
 config_url = "https://api.ligainternacional.net/api/1.0"
}
else if (url_domain === "dashtest.ligainternacional.net"){
  config_url = "https://apitest.ligainternacional.net/api/1.0"
 }



console.log("Backend Conected: " + config_url)

export default axios.create({
  baseURL: config_url
})
